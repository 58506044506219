import React from 'react'
import { Link } from 'theme-ui'
import SVG from 'react-inlinesvg'
import gatsbySVG from '../../assets/point-anomaly-logo.svg'

const styles = {
  link: {
    display: `inline-grid`,
    color: `heading`,
    fontWeight: `medium`,
    textDecoration: `none`,
    ':visited': {
      color: `heading`
    },
    ':hover': {
      color: `#000F27`
    },
    svg: {
      height: 24,
      ml: 0
    }
  }
}

const PoweredByGatsby = () => (
  <Link
    target='_blank'
    title='Point Anomaly'
    href='https://pointanomaly.com'
    rel='noopener'
    sx={styles.link}
  >
    Creado por
    {gatsbySVG && <SVG src={gatsbySVG} />}
  </Link>
)

export default PoweredByGatsby
