import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Content from '@solid-ui-blocks/Content/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block07'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

const MantenimientoSecadora = props => {
    const { allBlockContent } = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout {...props}>
            <Seo title='Mantenimiento de Secadora en Guatemala' description='Realizamos mantenimiento de Secadoras en Guatemala de todas las marcas. Técnicos especialistas y garantía en el trabajo realizado. ¡Cotiza aquí!' />
            <Header content={content['header']} />
            <Container variant='full' sx={styles.heroContainer}>
                <Hero content={content['hero']} reverse />
            </Container>
            <Divider space='5' />
            <Container variant='wide' sx={styles.featureOneContainer}>
                <FeatureOne content={content['feature-one']} />
            </Container>
            <Divider space='5' />
            <Content content={content['content-one-7']} />
            <Divider space='5' />
            <Footer content={content['footer']} />
        </Layout>
    )
}

export const query = graphql`
  query innerpageMantenimientoSecadoraBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/mantenimiento/secadora", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default MantenimientoSecadora
